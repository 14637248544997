<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Shipments</h3>
            </div>
            <div class="card-toolbar">
              <b-form-input
                v-model="filtershipments"
                type="search"
                placeholder="Type to search"
              ></b-form-input>
            </div>
          </div>
          <div class="card-body body-fluid">
            <div class="table-responsive">
              <b-table
                striped
                hover
                :items="shipments"
                id="shipments-tbl"
                class="text-nowrap"
                :fields="fields"
                :filter="filtershipments"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
              >
                <template #cell(tracking_no)="row">
                  <a class="a" @click="GetRecord(row.item)">{{
                    row.item.tracking_no
                  }}</a>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                align="right"
                aria-controls="shipments-tbl"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      shipments: [],
      isLoading: false,
      type: 0,
      rows: 0,
      perPage: 30,
      currentPage: 1,
      filtershipments: null,
      fields: [
        {
          key: "tracking_no",
          label: "Tracking No.",
        },
        { key: "receiver_name", label: "Receiver" },
        { key: "purchase_date", label: "Booking Date" },

        //   {
        //   key: "type",
        //   label: "Type",
        //   formatter: (value) => {
        //     if(value==1){
        //       return "Prealert";
        //     }
        //     if(value==2){
        //       return "Shipment";
        //     }
        //   },
        // },
        {
          key: "status",
          label: "Status",
          // formatter: (value) => {
          //   if (value == 1) {
          //     return "Prealert Received";
          //   }
          //   if (value == 2) {
          //     return "Pickup Request";
          //   }
          // },
        },
        {
          key: "created_at",
          label: "Created At",
          formatter: (value) => {
            return new Date(value).toLocaleString();
          },
        },
      ],
    };
  },
  components: {
    Loading,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Shipment" },
      { title: "All Shipments" },
    ]);
    this.isLoading = true;
    this.getAllShipments();
  },
  methods: {
    GetRecord(row) {
      this.$router.push({ name: "view-shipment", params: { id: row.id } });
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    filter() {
      if (this.type > 0) {
        this.shipments = this.allshipments.filter((x) => x.type == this.type);
      } else {
        this.shipments = this.allshipments;
      }
    },
    getAllShipments() {
      this.isLoading = true;
      ApiService.get("prealert")
        .then(({ data }) => {
          this.shipments = data;
          this.rows = this.shipments.length;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant("danger", response.data.error[field][0]);
          }
        });
    },
    makeToastVariant(variant = null, text) {
      this.$bvToast.toast(text, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
